<template>
  <div class="csn-registration-confirm"></div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {
  Module,
  ADD_FLASH,
  FlashType,
  CURRENT_LOCATION,
  REMOVE_FLASH,
  FlashId,
  REGISTRATION_CONFIRM,
} from '@/constants'
import { UserApi } from '@/api'

export default {
  name: REGISTRATION_CONFIRM,
  computed: {
    ...mapState(Module.LOCATION, [CURRENT_LOCATION]),
  },
  methods: {
    ...mapMutations(Module.FLASH, [ADD_FLASH, REMOVE_FLASH]),
  },
  async created() {
    const token = this.CURRENT_LOCATION?.query?.token

    if (token) {
      const { state, msg } = await UserApi.postTokenVerification({ token })

      this.ADD_FLASH({
        message: msg,
        type: FlashType[state],
        id: FlashId.ACC_ACTIVATION,
      })
    }
  },
}
</script>
